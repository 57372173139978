import React from 'react';
// import {show, hide} from "../customComponents/Notify";
import { store } from "../redux/store";
// var tinycolor = require("tinycolor2");
import {enqueueSnackbar } from 'notistack';



class Helper1{

  status = () => {
    return {0:'Awaiting approval', 1:'Active', 2:'Suspended'};
  }

}


export const capitalizeFirstLetter =(stro) => {
  if(!stro){ return ''; }
  let str = String(stro)
  return str?.charAt(0)?.toUpperCase() + str?.slice(1)
}


export const notify = (msg, code) => {


    let message;

    switch (code) {
      case 0:
        message = 'error';
        break;
      case 1:
        message = 'success';
        break;
      default:
        message = 'info';
    }



    enqueueSnackbar(msg, { 
                            variant: message, 
                            style: {
                              zIndex: 999999999999999999999,
                            }, 
                            className: 'Toastify__toast-container',
                          });
  
}


export const guessAdminRoute = () => {

  if (process.env.NODE_ENV === 'production') {

    
    const currentUrl = window.location.href;
    const urlWithoutSubdomain = currentUrl.replace(/^(?:https?:\/\/)?(?:[^@\/]+@)?/, '');
    const urlWithoutWww = urlWithoutSubdomain.replace(/^www./, '');
    const newlastUrl = urlWithoutWww.split("/")[0]

    let detectSubDomain =  newlastUrl.split(".")
    let newAdminBase = detectSubDomain?.length > 2?`${detectSubDomain[detectSubDomain?.length-2]}.${detectSubDomain[detectSubDomain?.length-1]}`:newlastUrl

    // return `https://admin.${newAdminBase}` //all live admin (API) address must look like admin.example.com, this app can be located on main or subdomain of same domain address with the admin

    return 'https://road-to-admini-pannel.orleans24.com'
    // return 'https://league1009.orleans24.com'; // a custom domain alias for the above worker. Does not exist on hosting account, only cloudflare and straight to worker




  }else{

    // return "https://league1009.orleans24.com";
    return "http://localhost/olaFootball2024.1.1/public";
    // return "http://192.168.33.113/olaFootball2024.1.1/public";



  }

}


export const addUrlToPath = (path) => {

  return guessAdminRoute() + '/' + path;

}


export const padNumber = (padThisNumber, length = 4, filler = 0, maximumNumber = 9999) => {
    
    let numberToPad = String(padThisNumber)

    if(numberToPad > maximumNumber){
        return numberToPad
    }

    let newpad = numberToPad?.padStart(length, filler)

    return newpad
}




export const displayTime = (dateString) => {
  const date = new Date(dateString);
  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    // timeZoneName: 'short'
  };
  
  return new Intl.DateTimeFormat('en-US', options).format(date);

}

export const formatTime = (dateString) =>  {
  const date = new Date(dateString);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  hours = hours < 10 ? `0${hours}` : hours;
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  return `${hours}:${minutes}`;
}



export const formatDate = (date) => {
  var d = new Date(date);
  var year = d.getFullYear();
  var month = d.getMonth() + 1;
  var day = d.getDate();

  var currentYear = new Date().getFullYear();
  var formattedDate = padNumber(month, 2) + "/" + padNumber(day, 2);

  if (year !== currentYear) {
    formattedDate += "/" + year;
  }

  return formattedDate;
}


export const niceDate = (paramObj) => {
  const dateObj = new Date(paramObj);
  return dateObj.getFullYear() + '-' + (dateObj.getMonth() + 1) + '-' + dateObj.getDate();
}


export const decimalCount = num => {
    const numStr = String(num)
    if(numStr.includes('.')){
        return numStr?.split('.')[1]?.length;
    }

    return 0
}


const getMother = () => store.getState()?.settings?.settings?.plans;

export const money = (myAmount, currency, addObj={}) => {

  const motherObj = getMother()?.filter((item, index) => item.mother.type == Number(currency))
  const selectedMother = motherObj?.[0]?.mother ?? '';
  var nf = new Intl.NumberFormat("en-US", { minimumFractionDigits: addObj?.decimals ?? selectedMother?.decimals ?? 2 } );
  let syn = selectedMother?.symbol ?? '';
  // let syn = '';

  let amount = nf.format(myAmount);

  if(addObj?.removeSymbol){
    return amount
  }else if(selectedMother?.symbollocation == 0){
    return syn +  ' ' + amount
  }else if(selectedMother?.symbollocation == 1){
    return amount + ' ' + syn
  }

  return amount;
  

}


export const FormatOnly = (amount) => {
  var nf = new Intl.NumberFormat();
  return (nf.format(amount))
}




  export const moneyStatus = () => {
    return ['Pending', 'Processing', 'Confirmed', 'Cancelled'];
  }


export const usefulColors = (code) => {
    let array = ['danger', 'success', 'info', 'warning'];
    return array[code] ?? 'warning';
}


export const randColors = [
   "#46c7b1", "#ffbc02", "#262734", "#8644f6", "#375d80", "#79e4fe", "#baeaac", "#182bd2", "#20fd4d", "#6762e0", "#482e4a", "#fc5640", "#43fa05", "#3cde14", "#37a776", "#3d41ac", "#0dc19a", "#c5e011", "#230eaa", "#d24daa", "#9faa91", "#0f2c13", "#7a429c", "#47505f", "#FFA500", "#FFA500", "#ea9e70", "#63b598", "#c6e1e8", "#a48a9e", "#c6e1e8", "#648177" ,"#0d5ac1" , "#f205e6" ,"#1c0365" ,"#14a9ad" ,"#4ca2f9"
];





export const byNextDate = (arr) => {
  let now = new Date();
  return arr?.filter(obj => new Date(obj.fixture_datetime) >= now).sort((a, b) => {
    return Math.abs(new Date(a.fixture_datetime) - now) -
           Math.abs(new Date(b.fixture_datetime) - now);
  });
}



export const byToDaysDate = (arr) => {

  var today = new Date();
  today.setHours(0, 0, 0, 0);

  var filteredArray = arr?.filter(function(obj) {
    var date = new Date(obj.fixture_datetime);
    return date >= today && date < new Date(today.getTime() + 86400000);
  });



  let now = new Date();
  return filteredArray?.filter(obj => new Date(obj.fixture_datetime) >= now).sort((a, b) => {
    return Math.abs(new Date(a.fixture_datetime) - now) -
           Math.abs(new Date(b.fixture_datetime) - now);
  });
}





export const byTomorrowsDate = (arr) => {
  const now = new Date();
  const tomorrow = new Date(now);
  tomorrow.setDate(now.getDate() + 1);
  tomorrow.setHours(0, 0, 0, 0);

  const filteredArray = arr?.filter((obj) => {
    const date = new Date(obj.fixture_datetime);
    return date >= tomorrow && date < new Date(tomorrow.getTime() + 86400000);
  });

  return (
    filteredArray
      ?.filter((obj) => new Date(obj.fixture_datetime) >= now)
      .sort((a, b) => {
        return (
          Math.abs(new Date(a.fixture_datetime) - now) -
          Math.abs(new Date(b.fixture_datetime) - now)
        );
      })
  );
}



export const fixtureStatuses = () => {
  return [
    ['CANC', 'Cancelled'],
    ['TBD', 'Time To Be Defined'],
    ['NS', 'Not Started'],
    ['1H', 'First Half, Kick Off'],
    ['HT', 'Halftime'],
    ['2H', 'Second Half, 2nd Half Started'],
    ['ET', 'Extra Time'],
    ['BT', 'Break Time'],
    ['P', 'Penalty In Progress'],
    ['SUSP', 'Match Suspended'],
    ['INT', 'Match Interrupted'],
    ['FT', 'Match Finished'],
    ['AET', 'Match Finished After Extra Time'],
    ['PEN', 'Match Finished After Penalty'],
    ['PST', 'Match Postponed'],
    ['CANC', 'Match Cancelled'],
    ['ABD', 'Match Abandoned'],
    ['AWD', 'Technical Loss'],
    ['WO', 'WalkOver'],
    ['LIVE', 'In Progress']
  ];
}



export const subStr = (stringToWorkOn, length = 10) => {

  let strReturned;
  let maxLength = length;
  let finalStr = stringToWorkOn ?? '';
  let string = finalStr.replace(/(\r\n|\n|\r)/gm, "").trim();

  if(string?.length > maxLength){
    strReturned = string.substring(0, maxLength) + '...'
  }else{
    strReturned = string
  }

  return strReturned;
  
}

export const stripTags = (datag) => {
    // Use the nullish coalescing operator to provide a default value for data
    let data = datag ?? '';

    // Remove HTML tags and trim the string
    let datagg = data?.replace(/<\/?[^>]+(>|$)/g, '')?.trim();

    return datagg;
}



export const base64ToBlob = (dataURI, contentType = 'image/jpeg') => {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this

    if(!dataURI){ return 'error' }

    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString?.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString?.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var bb = new Blob([ab], {type: contentType});
    return bb;
}



export const base64ToFile = (base64Data, filename = 'passport', contentType = 'image/jpeg') => {
    const blob = base64ToBlob(base64Data, contentType);

    const fileExtension = contentType.split('/').pop();

    // Append the file extension to the filename
    const fullFilename = `${filename}.${fileExtension}`;

    // Create a File object with the blob and set the filename
    const file = new File([blob], fullFilename, { type: contentType });

    return file;
};


export const groupObjectsByKey = (arr, key) => {
  // Create an empty object to hold the grouped objects
  const grouped = {};

  // Iterate through each object in the array
  arr?.forEach((obj) => {
    const value = obj[key]; // Extract the value of the specified key

    // If the value doesn't exist in the grouped object, create a new array for it
    if (!grouped[value]) {
      grouped[value] = [];
    }

    // Push the object into the corresponding group
    grouped[value].push(obj);
  });

  // Return the grouped objects
  // return grouped;

  let convertToArr = Object.entries(grouped).map(([key, items]) => ({
    key,
    items,
  }));

  return groupObjectsByKeyAndOrderCountries(convertToArr, 'key')

}


export const groupObjectsByKeyAndOrderCountries = (arr, key) => {
  
  return arr?.slice()?.sort((a, b) => {

    if (a[key] < b[key]) {
      return -1;
    }

    if (a[key] > b[key]) {
      return 1;
    }

    return 0;

  });

}


export const selectRandomObjects = (arr, numToSelect, seedString="") => {

  const shuffled = arr?.slice(); // Create a copy of the array

  for (let i = shuffled?.length - 1; i > 0; i--) {
      let consistency = Math.random()
      let consistency2 = ((seedString?.length) - i )/100
      const j = Math.floor(consistency2 * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }

  let finalOdds = arr?.filter(item => (item?.favourite ?? 0) == 0)?.slice(0, numToSelect) ?? []

  // check if favourite is there
  const getFavPart = shuffled?.filter(eachOdd => eachOdd?.favourite == 1) ?? []

  if(getFavPart?.length > 0){
    finalOdds = shuffled?.slice(0, numToSelect - 1)
  }

  return [...getFavPart, ...finalOdds]; // Return the first 'numToSelect' elements

}



export const allLanguages = () => {
  return [
            {
                country: 'UK',
                language: 'En',
                flag: require('../images/soccer/flags/uk.png'),
            },
            {
                country: 'Turkey',
                language: 'Tur',
                flag: require('../images/soccer/flags/turkey.png'),
            },
            {
                country: 'France',
                language: 'Fre',
                flag: require('../images/soccer/flags/france.png'),
            },
            {
                country: 'India',
                language: 'Hin',
                flag: require('../images/soccer/flags/india.png'),
            },
            {
                country: 'Spain',
                language: 'Spa',
                flag: require('../images/soccer/flags/spain.png'),
            },
            {
                country: 'China',
                language: 'Chi',
                flag: require('../images/soccer/flags/china.png'),
            },
            
      ]
}





export default Helper1;

