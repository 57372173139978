import React, {useState} from 'react';
import { Dropdown, } from 'react-bootstrap'
import thumb from '../../images/profile/2.png'
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../images/logo.png'
import { useSelector, useDispatch } from 'react-redux';
import {authActions, footballActions} from '../../redux/actions';
import {addUrlToPath, notify} from '../../utility/Helper1'
import { userSelect, homeScreenStatSelect, footballSelect, allRemoteSettingsSelect } from '../../redux/selectors/index';

import { MdOutlineTsunami, MdOutlineCircle, MdOutlineStadium, MdOutlineSports, MdOutlineSummarize } from "react-icons/md";
import { BiWindowClose, BiSolidTrashAlt } from "react-icons/bi";
import NoData from "../../utility/customComponents/NoData";
import LoadingIcon from "../../utility/customComponents/loader/LoadingIcon";
import copy from 'copy-to-clipboard';
import { AiOutlineClose } from "react-icons/ai";
import ShowMatches from './ShowMatches';
import useLanguage from '../../utility/config/hooks/useLanguage';
import parse from 'html-react-parser';


const Betslip = (props) => {

    const dispatch = useDispatch();

    const site_settings                                 = useSelector(allRemoteSettingsSelect);
    const navigate = useNavigate()
    const finance                           = useSelector(homeScreenStatSelect);

    const [betInputAmount, setBetInputAmount] = useState('')
    const [activeTab, setActiveTab] = useState(0)
    const [placingBet, setPlacingBet] = useState(false)
    const [currencySelected, setCurrencySelected] = useState(finance?.[1] ?? finance?.[0])
    const usingLanguage = useLanguage()

    let addHiddenClass = props?.addHiddenClassByDefault ?? true

    const toggleGetSlipModal = (boolValue) => {

        dispatch(footballActions.onToggleBetSlip({openBetSlip: boolValue}, response => {

            }),
        )

    }
console.log('finance', finance)

    const footballData  = useSelector(footballSelect);

    const removeFixtureFromCart = (fixture_id) => {

        dispatch(footballActions.onRemoveFixtureFromBetSlip({fixture_id}, response => {
                // notify('Bet removed from slip', 3)
            }),
        )

    }

    const clearBetSlipsCart = () => {

        dispatch(footballActions.onClearFixtureFromBetSlip(response => {
                toggleGetSlipModal(false)
                notify('Betslip cleared', 3)
            }),
        )

    }




    let totalOdds = footballData?.bet_slip?.betcart?.reduce((accumulator, obj) => accumulator + Number(obj.selectedOdd?.['percentage-winning']), 0)
    let totalBetAmount = footballData?.bet_slip?.betcart?.reduce((accumulator, obj) => accumulator + Number(obj.selectedOdd?.betAmount ?? 0), 0)

    const updateBetInputAmount = (val, fixture_id) => {

        if(isNaN(val)){
            notify('Input numeric characters only', 0)
            return;
        }
        
        dispatch(footballActions.onManageAmountOnBetSlip({val, fixture_id}, response => {

        }),
        )

        // footballData?.bet_slip?.betcart?.
        // setBetInputAmount(val)
    }

    const quickNumbers = currencySelected?.abbr=='NGN'?[5000, 10000, 20000, ]:[5, 10, 20, ]


    const copyId = (fixture_id) => {
        notify('Bet Id copied', 2)
        copy(fixture_id)
    }

    const navigateTo = (to) => {
        toggleGetSlipModal(false)
        navigate(to)
    }



    const submitBet = (e) => {

        e.preventDefault()
        if(placingBet){ return;}

        setPlacingBet(true)

        footballData?.bet_slip?.betcart?.forEach(eachToBet => {

            let amount = eachToBet?.selectedOdd?.betAmount ?? 0
            let betId = eachToBet?.selectedOdd?.id

            dispatch(footballActions.onSubmitBet({amount, currency: currencySelected.type, betId}, "football/bet", response => {

                if(response.code == 1){
                    removeFixtureFromCart(eachToBet?.fixture_id)
                }
                
                notify(`${eachToBet?.fixture_id}: ${response.msg}`, response.code)

            }))

        })

        setPlacingBet(false)

    }

    const quickImage = (curObj) => {
        return <img 
            onClick={() => setCurrencySelected(curObj)}
            src={curObj?.image} 
            alt="Original" 
            style={{width: 20, height: 20}}
            className={`rounded-lg shadow-lg ${currencySelected?.abbr != curObj?.abbr?'grayscale':''}`}
        />
    }
    return <>

        <div style={{border: 'none'}} className={`right__site__section dark_bg_5 ${addHiddenClass?'display991':''}`}>
            <div className="dark_bg_5 betslip__wrap" style={{border: 'none'}}>
                <h5 className="betslip__title flexify orange-bg-color-2" style={{gap: 15}}>
                    <span style={{flex: 1}}>
                        {usingLanguage['headlines']['betslip']}
                    </span>
                    
                    <div style={{display: 'flex', gap: 5, justifyContent: 'flex-end'}}>

                    
                        <div style={{display: 'flex', gap: 5}}>

                            {finance?.map(eachFinance => {
                               
                                return quickImage(eachFinance)
                            })}

                            <style>
                                {`
                                    .grayscale {
                                    filter: grayscale(100%);
                                    }
                                `}
                            </style>
                        </div>


                        {footballData?.bet_slip?.betcart?.length > 0 && activeTab == 0 && <div onClick={() => window.confirm("Clear betslip?") && clearBetSlipsCart()} className={"pointer"}>
                            <BiSolidTrashAlt size={18} color={"#7b839b"} style={{}} />
                        </div>}

                        {!addHiddenClass && <div onClick={() => toggleGetSlipModal(false)} className={"pointer"}>
                            <BiWindowClose size={18} color={"#7b839b"} style={{}} />
                        </div>}
                    
                    </div>

                </h5>
                <div className="nav" id="nav-taboo" role="tablist">

                    <button onClick={() => setActiveTab(0)} className={`nav-link ${activeTab == 0?'active':''}`}>{usingLanguage['headlines']['slip']}</button>
                    {/*<button onClick={() => setActiveTab(1)} className={`nav-link ${activeTab == 1?'active':''}`}>Booking</button>*/}
                    <button onClick={() => navigateTo('/my-bets')} className={`nav-link ${activeTab == 2?'active':''}`}>{usingLanguage['headlines']['my_bets']}</button>
                    <button onClick={() => navigateTo('/fund')} className={`nav-link ${activeTab == 2?'active':''}`}>{usingLanguage['headlines']['fund']}</button>
                
                </div>
                <div className="tab-content" style={addHiddenClass?{minHeight: '90vh'}:{}} id="nav-tabContent">
                    

                    {activeTab == 0 && 1>2 && <ShowMatches  heroTitle="My Games" data={footballData?.user_games} />}

                    {activeTab == 0 && <div className="tab-pane fade white-color-1 show active" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab" tabindex="0">
                       
                        {(footballData?.bet_slip?.betcart?.length ?? 0) > 0 && <div className="multiple__components">
                            
                            {footballData?.bet_slip?.betcart?.map((thisFixture, index) => {
                                
                                let eachOdds = thisFixture?.selectedOdd;

                                let theWinner = '';
                                let winnerText = 'Winner';

                                if(Number(eachOdds?.['home-scores']) > Number(eachOdds?.['away-scores'])){
                                    theWinner = thisFixture?.teams_home_name
                                }else if(Number(eachOdds?.['home-scores']) < Number(eachOdds?.['away-scores'])){
                                    theWinner = thisFixture?.teams_away_name
                                }else{
                                    theWinner="Draw"
                                    winnerText="Draw"
                                }


                                return <div className="multiple__items">
                                    
                                    <div className="flexify">

                                        <div className="dark_text_4">
                                            <span style={{fontSize: 9}}>{thisFixture?.league_name}</span>
                                        </div>

                                        {footballData?.bet_slip?.showBetId && <div className="text-right dark_text_4">
                                            <span style={{fontSize: 9}}>Bet ID: </span>
                                            <span style={{fontSize: 9, textDecoration: 'underline'}} onClick={() => copyId(thisFixture?.fixture_id)} className="pointer">{thisFixture?.fixture_id}</span>
                                        </div>}

                                    </div>

                                    <div className="multiple__head">

                                        <div className="multiple__left">

                                            {/*<span className="icons">
                                                de
                                            </span>*/}

                                            <span>
                                                {thisFixture?.teams_home_name} vs {thisFixture?.teams_away_name} {`${eachOdds?.['home-scores']}:${eachOdds?.['away-scores']}`}
                                            </span>

                                        </div>
                                        <a onClick={() => window.confirm('Remove?') && removeFixtureFromCart(thisFixture?.fixture_id)} href="#" className="cros">
                                            <AiOutlineClose color={"#f80"} />
                                        </a>
                                    </div>

                                    <div className="multiple__point">
                                        <span className="pbox" style={{display: 'flex', paddingBottom: 0, paddingTop: 0, justifyContent: 'center', alignItems: 'center'}}>
                                            {Number(eachOdds?.['percentage-winning'])?.toFixed(1)}%
                                        </span>
                                        <span className="rightname">
                                                <span className="fc white-color-1">
                                                    {theWinner}
                                                </span>
                                        <span className="point">
                                                    {winnerText}
                                                </span>
                                        </span>
                                    </div>

                                    <div className="total__odds">
                                        <div className="wrapper">
                                            <div className="rsult" style={{marginBottom: 12}}>
                                                <input maxLength="10" onChange={(e) => updateBetInputAmount(e.target.value, thisFixture?.fixture_id)} placeholder={""} value={eachOdds?.betAmount ?? ''} 
                                                    style={{width: '100%', border: 'none', height: 30, fontSize: 15, borderWidth: 0}} type={"text"} />
                                            </div>
                                            <div className="buttons" style={{display: 'flex', gap: 2, justifyContent: 'center', alignItems: 'center'}}>
                                                {quickNumbers.map(item => <button className="gold-gradient-1" onClick={() => updateBetInputAmount(item, thisFixture?.fixture_id)} style={{display: 'flex', flex: 1, gap: 5, justifyContent: 'center', alignItems: 'center'}} type="button">{item}</button>)}
                                            </div>
                                        </div>
                                    </div>


                                </div>})}



                            <div className="total__odds">
                                <div className="total__head">
                                    <h6 className="odd font-14">
                                        {usingLanguage?.['random_text']?.['betslip']?.['total_odds']}
                                    </h6>
                                    <span className="font-14">
                                        {totalOdds?.toFixed(2)}%
                                    </span>
                                </div>


                                <div className="total__head">
                                    <h6 className="odd font-14">
                                        {usingLanguage?.['random_text']?.['betslip']?.['total_bet_amount']} ({footballData?.bet_slip?.betcart?.length} bet{footballData?.bet_slip?.betcart?.length<=1?'':'s'})
                                    </h6>
                                    <span className="font-14">
                                        {totalBetAmount?.toFixed(2)}
                                    </span>
                                </div>


                                <div className="total__head">
                                    <h6 className="odd font-14">
                                        {usingLanguage?.['random_text']?.['betslip']?.['possible_winnings']}
                                    </h6>
                                    <span className="font-14">
                                        {(totalBetAmount+(totalBetAmount*(totalOdds/100))).toFixed(2)}
                                    </span>
                                </div>

                                <div className="total__head">
                                    <h6 className="odd font-14">
                                        Currency selected
                                    </h6>
                                    <span className="font-14">
                                        {currencySelected?.name}
                                    </span>
                                </div>

                                


                            </div>
 
                            {footballData?.bet_slip?.betcart?.length > 1 && <div className="aert alert-info" style={{border: 'none', marginBottom: 15, padding: 3}} >
                                {usingLanguage?.['random_text']?.['betslip']?.['multiple_bet_note']}
                            </div>}

                            <button style={{}} disabled={placingBet} onClick={(e) => window.confirm(`${footballData?.bet_slip?.betcart?.length} total game${footballData?.bet_slip?.betcart?.length > 1?'s':''} is about to be played. Click OK to continue`) && submitBet(e)} 
                                style={{border: 'none', width: '100%'}} 
                                className="btn btn-warning orange-bg-color-1">
                                <span>{usingLanguage?.['random_text']?.['betslip']?.['place_bet']} <LoadingIcon active={placingBet} color={"#fff"} /></span>

                                

                            </button>
                        </div>}

                        {(footballData?.bet_slip?.betcart?.length ?? 0) == 0 && <div>

                            <NoData textAreaStyle={{color: '#fff'}} textAreaClassName="white-color-1" title={<span style={{paddingLeft: 20, paddingRight: 20, }}>
                                {parse(usingLanguage?.['random_text']?.['betslip']?.['empty_slip_note'] ?? '')}



                            </span>} />

                        </div>}

                    </div>}






                    {activeTab == 1 && <div className="tab-pane fade white-color-1 show active" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab" tabindex="0">
                        

                        <div className="multiple__components">

                            <div className="total__odds">
                                


                                <div className="alert alert-info dark_bg_2" style={{border: 'none'}} >
                                    Please insert a bet ID below.
                                </div>


                                <div className="wrapper">
                                    <div className="result">
                                        <input maxLength="10" onChange={(e) => updateBetInputAmount(e.target.value)} placeholder={"Bet ID"} value={betInputAmount} style={{width: '100%', border: 'none', height: 50, fontSize: 25}} type={"text"} />
                                    </div>
                                </div>


                            </div>
                            
                            <button disabled={placingBet} onClick={() => window.confirm(`${(footballData?.bet_slip?.betcart?.length*betInputAmount).toFixed(2)} will be charged from your balance. Click OK to continue`) && submitBet()} 
                                    style={{border: 'none', width: '100%'}} 
                                    className="btn btn-warning orange-bg-color-1">
                                    <span>Book <LoadingIcon active={placingBet} color={"#fff"} /></span>

                            </button>

                        </div>
                        

                    </div>}



                </div>
            </div>
        </div>

    </>
};

export default Betslip;