import React, {useState, useEffect, useRef} from 'react';
import { Dropdown, } from 'react-bootstrap'
import thumb from '../../images/profile/2.png'
import { Link, useNavigate, useLocation } from 'react-router-dom';
import logo from '../../images/logo.png'
import { useSelector, useDispatch } from 'react-redux';
import {authActions, settingActions, footballActions} from '../../redux/actions';
import {addUrlToPath, notify, money, allLanguages, lang} from '../../utility/Helper1'
import { userSelect, homeScreenStatSelect, footballSelect, allLocalSettingsSelect, allTradeSelect, allRemoteSettingsSelect } from '../../redux/selectors/index';
import { AiOutlinePoweroff, AiOutlineBell, AiOutlineInfoCircle, AiOutlineHome, AiOutlineWallet, AiOutlineBank, AiOutlineApartment, AiOutlineUser, AiOutlineTool, AiOutlineRollback, AiOutlineUserAdd, AiOutlineUserSwitch } from "react-icons/ai"
import Select from 'react-select';
import BootstrapSelect from 'react-bootstrap-select-dropdown';

import { SlArrowDown, SlArrowUp } from "react-icons/sl";
import { MdSportsGymnastics, MdHomeFilled, MdOutlineStackedBarChart, MdCalendarMonth, MdCallSplit, MdSearch, MdFavorite, MdCardGiftcard, MdLiveTv, MdCancel, MdDataSaverOff } from "react-icons/md";
import Slider from "react-slick";
import Clock from 'react-live-clock';
import { LuCircleDotDashed, LuCircleOff } from "react-icons/lu";
import useLanguage from '../../utility/config/hooks/useLanguage';




    // main slider
    const settings = {
        dots: false,
        infinite: false,
        speed: 1000,
        slidesToShow: 10,
        slidesToScroll: 1,
        autoplay: false,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 8,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 900,
              settings: {
                slidesToShow: 6,
                slidesToScroll: 1,
                initialSlide: 1
              }
            },,
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 5,
                slidesToScroll: 1,
                initialSlide: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1
              }
            }
        ]
    };



const Header = (props) => {

    const dispatch = useDispatch();

    const site_settings                                 = useSelector(allRemoteSettingsSelect);
    const userData = useSelector(userSelect);
    const finance = useSelector(homeScreenStatSelect);
    const tradesData = useSelector(allTradeSelect);


    const avatarImg = ((userData?.image ?? null) != null)?addUrlToPath(userData?.image):require('../../images/profile/user.png')
    const demoAccount = userData?.demoAccount == 1?'Demo Account':''
    const logo = site_settings?.site_settings?.logo

    const navigate = useNavigate()
    const location = useLocation();
    const footballData  = useSelector(footballSelect);
    const [showLanguage, setShowLanguage] = useState(false)

    const [selectedLanguage, setSelectedLanguage] = useState(null)
    const local_settings    = useSelector(allLocalSettingsSelect);
    
    const usingLanguage = useLanguage()

    const flexboxRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
          localStorage.setItem('scrollPosition', flexboxRef?.current?.scrollLeft);
        };

        flexboxRef?.current?.addEventListener('scroll', handleScroll);

        return () => {
          flexboxRef?.current?.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
          const savedScrollPosition = localStorage.getItem('scrollPosition');
          if (savedScrollPosition) {
            flexboxRef.current.scrollLeft = parseInt(savedScrollPosition);
          }
    }, []);


    const toggleBetId = (boolValue) => {

        dispatch(footballActions.onToggleBetSlip({showBetId: boolValue}, response => {
                notify(`Bet id ${boolValue?'displayed':'hidden'}`, 2)
            }),
        )

    }

    const manageShowLanguage = () => {
        setShowLanguage(el => !el)
    }

    const selectALanguage = (objectSelected) => {

        dispatch(settingActions.onManageLocalSettings({activeLanguageObject: objectSelected}, response => {
            manageShowLanguage()
        }
        ))        

    }

    useEffect(() => {
        setSelectedLanguage(local_settings?.activeLanguageObject ?? allLanguages()?.[0])
    }, [local_settings?.activeLanguageObject])
    

    const languageDropDown = () => {
        
        return <div style={{gap: 20}} className="flexify">

            <div className="pointer" style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                {!(footballData?.bet_slip?.showBetId ?? false) && <LuCircleDotDashed style={{color: '#fff'}} onClick={() => toggleBetId(true)} size={30} className="white" />}

                {footballData?.bet_slip?.showBetId && <LuCircleOff style={{color: '#fff'}} onClick={() => toggleBetId(false)} size={30} className="white" />}
            </div>

            <Link to={"/wallet"} style={{color: '#fff'}} className="unchanged-white-color-1">

                <img style={{width: 30, height: 30}} src={require('../../images/wallet.png')} alt={'balance'} />
                {/* <img style={{width: 30, height: 30}} src={site_settings?.plans?.[0]?.mother?.image} alt={site_settings?.plans?.[0]?.mother?.abbr} />
                {" "}
                
                {money(finance?.[0]?.balance ?? 0, 1, {removeSymbol: true})} */}


            </Link>

            <div onClick={manageShowLanguage} style={{width: 90, marginRight: 20, flex: 1, display: 'flex', alignItems: 'center', zIndex: 9999}} 
                className="pointer">

                <div className="lag" style={{marginRight: 10}}>
                    <img style={{height: 25}} src={selectedLanguage?.flag} alt="flag" />
                </div>

                <span className="current unchanged-white-color-1">
                    {selectedLanguage?.language}
                </span>

                {!showLanguage && <SlArrowDown size={15} style={{marginLeft: 8}} className="unchanged-white-color-1" />}
                {showLanguage && <SlArrowUp size={15} style={{marginLeft: 8}} className="unchanged-white-color-1" />}

            </div>

            <div className={`language-container ${showLanguage?'active':''}`} style={{position: 'absolute', zIndex: 1, 
                            color: '#fff', top: 40, left: 130, width: 80, padding: 9, paddingBottom: 0,
                            backgroundColor: '#202a39', textAlign: 'center'}}>

                {allLanguages()?.map(item => <div onClick={() => selectALanguage(item)} style={{gap: 5, marginBottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center'}} className="pointer">
                    <img className="flag-option-images" height={35} width={40} style={{height: 35, width: 40}} src={item.flag} alt={`${item.country} flag`} /> <div>{item.language}</div>
                </div>)}
                
            </div>
            
        </div>

    }



    const headerMenu = [
            {
                title: usingLanguage['menu2']['home'],
                icon: MdHomeFilled,
                whenClicked: () => {navigate('/')},
                routeFocused: '/'
            },
            {
                title: usingLanguage['menu2']['search'],
                icon: MdSearch,
                whenClicked: () => {navigate('/search')},
                routeFocused: '/search'
            },
            {
                title: usingLanguage['menu2']['today'],
                icon: MdCalendarMonth,
                whenClicked: () => {navigate('/today')},
                routeFocused: '/today'
            },
            {
                title: usingLanguage['menu2']['tomorrow'],
                icon: MdCallSplit,
                whenClicked: () => {navigate('/tomorrow')},
                routeFocused: '/tomorrow'

            },
            {
                title: usingLanguage['menu2']['my_league'],
                icon: MdFavorite,
                whenClicked: () => {navigate('/my-leagues')},
                routeFocused: '/my-leagues'
            },
            {
                title: usingLanguage['menu2']['bonus_odd'],
                icon: MdCardGiftcard,
                whenClicked: () => {navigate('/bonus-odd')},
                routeFocused: '/bonus-odd'
            },
            {
                title: usingLanguage['menu2']['live'],
                icon: MdLiveTv,
                whenClicked: () => {navigate('/live')},
                routeFocused: '/live'
            },
            {
                title: usingLanguage['menu2']['cancelled'],
                icon: MdCancel,
                whenClicked: () => {navigate('/cancelled')},
                routeFocused: '/cancelled'
            },
            {
                title: usingLanguage['menu2']['results'],
                icon: MdDataSaverOff,
                whenClicked: () => {navigate('/results')},
                routeFocused: '/results'
            },

        ]





    const doLogOutNowNow = () => {
        dispatch(authActions.onLogout())

        dispatch(settingActions.onManageLocalSettings({lastPrivateUrl: null}, response => {

        }))

        notify('Logout successful', 1)
    }


    const mobileMenu = [
            {
                title: usingLanguage['menu1']['deposit'],
                icon: MdSearch,
                whenClicked: (e) => {e.preventDefault(); navigate('/fund')},
                onlyOnMobile: false,
                show: true,
                icon: AiOutlineWallet,
            },
            {
                title: usingLanguage['menu1']['wallet'],
                icon: MdCalendarMonth,
                whenClicked: (e) => {e.preventDefault(); navigate('/wallet')},
                routeFocused: '/wallet',
                onlyOnMobile: false,
                show: true,
                icon: AiOutlineBank,
            },
            {
                title: usingLanguage['menu1']['invitation'],
                icon: MdHomeFilled,
                whenClicked: (e) => {e.preventDefault(); navigate('/invitation-link')},
                routeFocused: '/invitation-link',
                onlyOnMobile: false,
                show: true,
                icon: AiOutlineApartment,
            },
            {
                title: usingLanguage['menu1']['profile'],
                icon: MdHomeFilled,
                whenClicked: (e) => {e.preventDefault(); navigate('/settings-profile')},
                routeFocused: '/settings-profile',
                onlyOnDesktop: true,
                show: true,
                icon: AiOutlineUser,
            },
            {
                title: usingLanguage['menu1']['settings'],
                icon: MdCalendarMonth,
                whenClicked: (e) => {e.preventDefault(); navigate('/settings-password')},
                routeFocused: '/settings-password',
                onlyOnMobile: false,
                show: true,
                icon: AiOutlineTool,
            },
            {
                title: usingLanguage['menu1']['logout'],
                icon: MdCalendarMonth,
                whenClicked: (e) => {

                        e.preventDefault(); 
                        doLogOutNowNow()
                        
                    },

                routeFocused: '',
                onlyOnMobile: false,
                show: userData?.accountId,
                icon: AiOutlineRollback,
            },
            {
                title: usingLanguage['menu1']['sign_in'],
                icon: MdHomeFilled,
                whenClicked: (e) => {e.preventDefault(); navigate('/signin')},
                onlyOnMobile: true,
                show: !userData?.accountId,
                icon: AiOutlineUserSwitch,
            },
            {
                title: usingLanguage['menu1']['sign_up'],
                icon: MdSearch,
                whenClicked: (e) => {e.preventDefault(); navigate('/signup')},
                onlyOnMobile: true,
                show: !userData?.accountId,
                icon: AiOutlineUserAdd,
            }
        ]




    const showMatchMenuOptions = props?.showMatchMenuOptions ?? true

    return (
        <div style={{position: 'fixed', top: 0, left: 0, zIndex: 9, width: '100%'}}>





            <header className="header-section red-bg-1">
                <div className="container-fluid p-0 red-bg-1">
                    <div className="header-wrapper red-bg-1" style={{height: 75}}>
                        <div className="menu__left__wrap red-bg-1">
                            <div style={{marginLeft: 10}} className="logo-menuFaked">
                                <Link to="/" className="logo">
                                    <img style={{height: 40}} src={addUrlToPath(logo)} alt="logo" />
                                </Link>
                            </div>






                            {/*mobile menu*/}
                            <input className="mobile_menu" type="checkbox" id="navcheck" role="button" title="menu"/>
                            <label className="mobile-menu-label d-lg-none" style={{}} for="navcheck" aria-hidden="true" title="menu">
                                <span class="burger">
                                    <span class="bar">
                                        <span class="visuallyhidden">Menu</span>
                                    </span>
                                </span>
                            </label>
                            <nav id="menu">
                                
                                {mobileMenu?.map(menu => {

                                    if(!menu.show){ return <></>}
                                    if(menu?.onlyOnDesktop){ return <></>}

                                    let TheIcon = menu.icon

                                    return <a href="#" onClick={menu?.whenClicked}>
                                            <TheIcon style={{marginRight: 4}}/>
                                            {menu?.title?.toUpperCase()}.</a>})}
                                
                            </nav>








                            <div className="lang d-flex align-items-center px-2  d-lg-none">
                                <div className="language__wrap">
                                    
                                    {languageDropDown()}

                                </div> 
                                <div className="header-bar d-lg-none">
                                    {/*<span></span>
                                    <span></span>
                                    <span></span>*/}
                                </div>

                            </div>

                            <ul className="main-menu" style={{marginLeft: 25}}>

                                {mobileMenu?.map(menu => {

                                    if(!menu?.show){ return <></>}
                                    if(menu?.onlyOnMobile){ return <></>}
                                    let TheIcon = menu.icon

                                    return <li>
                                        <a href="#" onClick={menu?.whenClicked}>
                                            <TheIcon />
                                            {menu?.title?.toUpperCase()}
                                        </a>
                                    </li>})}


                            </ul>
                        </div>
                        <div className="mneu-btn-grp">
                            <div className="language__wrap">

                               
                                {languageDropDown()}


                            </div>

                            {!userData?.accountId && <>
                                <Link to="/signin" className="cmn--btn">
                                    <span>{usingLanguage['menu1']['sign_in']}</span>
                                </Link>
                                <Link to="/signup" className="cmn--btn2">
                                    <span className="rela">{usingLanguage['menu1']['sign_up']}</span>
                                </Link>
                            </>}

                        </div>
                    </div>
                </div>
            </header>


            {showMatchMenuOptions && <section className="main-bg-color-1" style={{width: '100%', padding: 0, paddingTop: 5, paddingBottom: 5}}>

                    
                    <div ref={flexboxRef} className="scroll-container restore-scroll" style={{display: 'flex', gap: 5, overflowX: 'auto'}}>

                        {headerMenu?.map((item, index) => {

                            let isSelected = location.pathname == item?.routeFocused

                            const Icon      = item.icon
                            const color     = isSelected?'#9c0233':'#000'
                            const bgColor   = isSelected?'#044246':'#202a39'

                            return <li onClick={(e) => {e.preventDefault(); item?.whenClicked?.()}} className="header-boxed-menu-bg pointer" style={{borderRadius: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', height: 80, minWidth: 150}}>
                                  <div style={{borderRadius: 0, textAlign: 'center', justifyContent: 'center', alignItems: 'center'}} className="nav-link header-boxed-menu-text">
                                    
                                    <div style={{marginBottom: 0}} className="icons">
                                        <Icon color={color} size={17} />
                                    </div>

                                    <div className="" style={{color, marginTop: 4}}>
                                        {item?.title}
                                    </div>

                                  </div>
                                </li>
                        })}

                    </div>


            </section>}



        </div>
    );
};

export default Header;